import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/services/coach-hire-services-disabled-access.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Wheelchair disabled access coach hire" description="Book coach hire with disabled access from Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Disabled access coach hire</h1>
            </div>

            <div className="news-article-body">

              <p>Many of our clients are unaware that we are able to offer an executive class coach with disabled access/wheelchair lift. We are therefore pleased to announce that Tetley’s Coaches have recently added a wheelchair accessible coach to the fleet.</p>
              <p>
                <img className="service-img" src={mainImg} alt="Wheelchair disabled access coach hire" />
              </p>
              <p>The coach, a British built Plaxton Panther has a fully automatic wheelchair lift to allow easier access for wheelchairs and physically impaired passengers. The coach can accommodate one wheelchair plus 47 seated passengers. Access into the coach is by a lift through the wide access entrance door above the front axle.</p>
              <p>The coach was initially purchased to operate solely on our National Express contract but is now available to private hire groups. It is fully seat-belted, has air conditioning, a rear toilet and washroom, and provides a high standard of comfort to all passengers.</p>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
